// This object is provided as `config.rtcConfig` to Trystero's `joinRoom`
// function: https://github.com/dmotz/trystero#joinroomconfig-namespace
//
// https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection#parameters
export const rtcConfig: RTCConfiguration = {
  iceServers: [
    {
      urls: [ "stun:u3.xirsys.com" ]
    },
    {
      username: "FSG3MhL8jMBJCbaRKyUjVZGjG9GSXsGU8cr0MnL5J5rRhHXRVn4-GjA4YVLbLx9wAAAAAF5v4xVqempvaG5zb24=",
      credential: "af5e5790-67c5-11ea-ab10-d628af64b1ad",
      urls: [
        "turn:u3.xirsys.com:80?transport=udp",
        "turn:u3.xirsys.com:3478?transport=udp",
        "turn:u3.xirsys.com:80?transport=tcp",
        "turn:u3.xirsys.com:3478?transport=tcp",
        "turns:u3.xirsys.com:443?transport=tcp",
        // "turns:u3.xirsys.com:5349?transport=tcp",
      ]
    }
  ]
}
